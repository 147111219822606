@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

body{
    font-family:'Oswald', sans-serif;
}

.nav-link{
    font-family:'Oswald', sans-serif !important;
}

.navbar{
    height: 100px !important;
    background-color: #3ab54a !important;
    color: #ffffff;
}

.navbar-brand{
    color: #ffffff !important;
}

.nav-link{
    color:#ffffff !important;
}

.meeta-event-schedule-banner {
    height: 510px;
    padding: 70px 40px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;
}

.meeta-event-schedule-tabs {
    display: flex;
    gap: 30px;
}

.meeta-event-schedule-time {
    margin-bottom:10px;
    width: 165px;
    padding: 20px 0;
    border: 1px dashed #000000 !important;
    text-align: center;
    border-radius: 8px;
    transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
    cursor: pointer;
}

.meeta-event-schedule-day {
    font-size: 30px;
    font-weight: 700;
    color: #000 !important;
    margin-bottom: 3px;
}

.meeta-event-schedule-time:hover {
    border: 1px solid #00ad1f !important;
    background-color: #00ad1f !important;
}

.meeta-event-schedule-time:hover > .meeta-event-schedule-day {
    color:#ffffff !important;
}

.sinPunto{
    list-style-type:none !important;
}

.meeta-section-title .main-title {
    font-size: 40px !important;
    font-weight: 700 !important;
    line-height: 1.1 !important;
}


.text-pink{
    color: #fc2c7c !important;
}

.btn{
    display: inline-block !important;
    position: relative !important;
    overflow: hidden !important;
    border-radius: 0px !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    font-family: "OpenSans", sans-serif !important;
    height: 50px !important;
    line-height: 50px !important;
    padding: 0px 35px !important;
    border-width: 0 !important;
    border-style: solid !important;
    box-shadow: none !important;
    transition: all 0.3s ease 0s !important;
    cursor: pointer !important;
    z-index: 1 !important;
    white-space: nowrap !important;
}

.dotted{
  border: 1px dotted #000000 !important;
}

.inputCantidad{
    height: 50px !important;
    width: 80px !important;
}


input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

/* Absolute Center Spinner */
.loading {
    position: fixed;
    z-index: 999;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 50px;
    height: 50px;
  }
  
  /* Transparent Overlay */
  .loading:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,0.5);
  }
  
  /* :not(:required) hides these rules from IE9 and below */
  .loading:not(:required) {
    /* hide "loading..." text */
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
  }
  
  .loading:not(:required):after {
    content: '';
    display: block;
    font-size: 10px;
    width: 50px;
    height: 50px;
    margin-top: -0.5em;
  
    border: 15px solid #00ad1f;
    border-radius: 100%;
    border-bottom-color: transparent;
    -webkit-animation: spinner 1s linear 0s infinite;
    animation: spinner 1s linear 0s infinite;
  
  
  }
  
  /* Animation */
  
  @-webkit-keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @-moz-keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @-o-keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  .inputCantidad{
    text-align: center;
    font-size: 20px;
  }

.PhoneInputInput {
    display: block !important;
    width: 100% !important;
    padding: 0.375rem 0.75rem !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    color: #212529 !important;
    background-color: #fff !important;
    background-clip: padding-box !important;
    border: 1px solid #ced4da !important;
    appearance: none !important;
    border-radius: 0.375rem !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.btn-bottom{
  position: absolute !important;
  bottom: 20px !important;
  width:80%;
  background-color: transparent !important;
  border: 1px #ced4da solid !important;
}

.btn-bottom:hover{
  background-color: #ced4da !important;
  color: #212529 !important; ;
  border: 1px #ced4da solid !important;
}

.precio-antes{
  text-decoration-line: line-through !important;
}